import React, { ReactNode } from 'react'
import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import { OutgoingLink } from '@47ng/chakra-next'
import { Logo } from './Logo'

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  )
}

export const FooterNav: React.FC = ({ ...props }) => {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      {...props}
    >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
          spacing={8}
        >
          <Stack spacing={[4, 4, 6]}>
            <Box>
              <Logo h={10} color={useColorModeValue('gray.600', 'gray.300')} />
            </Box>
            <Text fontSize={'sm'}>
              © 2021 <OutgoingLink href="https://47ng.com">47ng</OutgoingLink>.
              All rights reserved
            </Text>
            <Text fontSize={'sm'}>
              <OutgoingLink href="https://www.notion.so/47ng/Repoverview-f04949cf58b34b41b1422dbd378a5444">
                Built in the open
              </OutgoingLink>{' '}
              by{' '}
              <OutgoingLink href="https://francoisbest.com">
                François Best
              </OutgoingLink>
            </Text>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Product</ListHeader>
            <Link href={'#'}>Overview</Link>
            <Link href={'#'}>Features</Link>
            <Link href={'#'}>Pricing</Link>
            <Link href={'#'}>Changelog</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Support</ListHeader>
            <Link href={'#'}>Documentation</Link>
            <Link href={'#'}>Terms of Service</Link>
            <Link href={'#'}>Privacy Policy</Link>
            <Link href={'#'}>Status</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Follow Us</ListHeader>
            <OutgoingLink href={'https://twitter.com/repoverview'}>
              Twitter
            </OutgoingLink>
            <OutgoingLink href={'https://github.com/franky47/repoverview'}>
              GitHub
            </OutgoingLink>
            <OutgoingLink
              href={'https://www.indiehackers.com/product/repoverview'}
            >
              IndieHackers
            </OutgoingLink>
            <Link href={'#'}>ProductHunt</Link>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  )
}
