import React from 'react'
import { NextPage } from 'next'
import { HeaderNav } from 'src/components/landing/HeaderNav'
import { FooterNav } from 'src/components/landing/FooterNav'
import { Hero } from 'src/components/landing/Hero'

// --

const LandingPage: NextPage = () => {
  return (
    <>
      <HeaderNav />
      <Hero />
      <FooterNav />
    </>
  )
}

export default LandingPage
