import React from 'react'
import { useToken } from '@chakra-ui/react'
import { Svg, SvgProps } from '@47ng/chakra-next'

export interface LogoProps extends SvgProps {
  color?: string
}

export const Logo: React.FC<LogoProps> = ({ color = 'gray.700', ...props }) => {
  const resolvedColor = useToken('colors', color)
  return (
    <Svg viewBox="0 0 1207 256" fill="none" {...props}>
      <title>Repoverview</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.238 41.0944C202.446 45.7474 206.985 52.3111 209.172 59.7956L236.542 151.843C236.836 152.866 237 153.922 237 155V165.989C237 166.066 237 166.143 236.999 166.22C236.848 136.249 212.506 112 182.5 112C152.4 112 128 136.4 128 166.5C128 174.135 129.57 181.404 132.405 188H124.013C126.584 181.672 128 174.751 128 167.5C128 137.4 103.6 113 73.5 113C43.6614 113 19.4235 136.979 19.0055 166.719C19.0021 166.479 19.0003 166.24 19 166V155C19.0038 153.931 19.158 152.868 19.4578 151.843L46.8277 59.7956C49.0085 52.3202 53.5374 45.7623 59.7321 41.1099C65.9268 36.4575 73.4518 33.9625 81.1736 34.0007C90.1451 33.9996 98.7926 37.385 105.416 43.4914C112.04 49.5978 116.161 57.9836 116.969 67.0006H139.042C139.839 57.9931 143.946 49.6123 150.556 43.5058C157.166 37.3993 165.8 34.0088 174.761 34.0007C182.493 33.9517 190.031 36.4415 196.238 41.0944Z"
        fill={resolvedColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.5 120C47.2562 120 26 141.564 26 168.189C26 189.512 39.5969 207.523 58.4781 213.908C60.8531 214.33 61.7437 212.884 61.7437 211.619C61.7437 210.474 61.6844 206.68 61.6844 202.644C49.75 204.873 46.6625 199.692 45.7125 196.982C45.1781 195.596 42.8625 191.319 40.8437 190.175C39.1812 189.271 36.8062 187.043 40.7844 186.982C44.525 186.922 47.1969 190.476 48.0875 191.922C52.3625 199.21 59.1906 197.162 61.9219 195.897C62.3375 192.765 63.5844 190.657 64.95 189.452C54.3812 188.247 43.3375 184.091 43.3375 165.659C43.3375 160.418 45.1781 156.081 48.2063 152.708C47.7313 151.503 46.0687 146.564 48.6812 139.938C48.6812 139.938 52.6594 138.673 61.7437 144.877C65.5437 143.793 69.5812 143.251 73.6187 143.251C77.6562 143.251 81.6937 143.793 85.4937 144.877C94.5781 138.613 98.5562 139.938 98.5562 139.938C101.169 146.564 99.5063 151.503 99.0313 152.708C102.059 156.081 103.9 160.358 103.9 165.659C103.9 184.151 92.7969 188.247 82.2281 189.452C83.95 190.958 85.4344 193.849 85.4344 198.367C85.4344 204.812 85.375 209.993 85.375 211.619C85.375 212.884 86.2656 214.39 88.6406 213.908C98.0701 210.678 106.264 204.53 112.069 196.329C117.874 188.127 120.997 178.286 121 168.189C121 141.564 99.7437 120 73.5 120Z"
        fill={resolvedColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.5 120C156.256 120 135 141.564 135 168.189C135 189.512 148.597 207.523 167.478 213.908C169.853 214.33 170.744 212.884 170.744 211.619C170.744 210.474 170.684 206.68 170.684 202.644C158.75 204.873 155.662 199.692 154.712 196.982C154.178 195.596 151.862 191.319 149.844 190.175C148.181 189.271 145.806 187.043 149.784 186.982C153.525 186.922 156.197 190.476 157.087 191.922C161.362 199.21 168.191 197.162 170.922 195.897C171.337 192.765 172.584 190.657 173.95 189.452C163.381 188.247 152.338 184.091 152.338 165.659C152.338 160.418 154.178 156.081 157.206 152.708C156.731 151.503 155.069 146.564 157.681 139.938C157.681 139.938 161.659 138.673 170.744 144.877C174.544 143.793 178.581 143.251 182.619 143.251C186.656 143.251 190.694 143.793 194.494 144.877C203.578 138.613 207.556 139.938 207.556 139.938C210.169 146.564 208.506 151.503 208.031 152.708C211.059 156.081 212.9 160.358 212.9 165.659C212.9 184.151 201.797 188.247 191.228 189.452C192.95 190.958 194.434 193.849 194.434 198.367C194.434 204.812 194.375 209.993 194.375 211.619C194.375 212.884 195.266 214.39 197.641 213.908C207.07 210.678 215.264 204.53 221.069 196.329C226.874 188.127 229.997 178.286 230 168.189C230 141.564 208.744 120 182.5 120Z"
        fill={resolvedColor}
      />
      <path
        d="M321.027 89.8667C331.8 89.8667 336.6 95.84 336.6 104.267C336.6 113.547 329.453 119.307 319.32 119.307H303.96V89.8667H321.027ZM353.667 143.947C351.853 135.307 347.587 129.013 338.413 127.413C349.827 124.213 357.187 113.333 357.187 101.92C357.187 86.3467 345.987 73.3334 323.053 73.3334H283.8V180H303.96V135.52H319.533C327.747 135.52 331.693 139.467 333.293 147.36L339.693 180H361.24L353.667 143.947Z"
        fill={resolvedColor}
      />
      <path
        d="M391.386 133.387C393.306 122.293 399.493 116 407.813 116C415.813 116 421.786 121.76 423.599 133.387H391.386ZM442.693 141.6C442.693 116.747 428.506 101.6 407.493 101.6C386.479 101.6 371.226 117.6 371.226 141.6C371.226 165.813 388.186 181.6 411.013 181.6C421.573 181.6 430.533 178.4 438.319 171.573L427.439 160.693C423.813 164.427 418.053 166.667 412.079 166.667C400.986 166.667 392.773 159.2 391.066 146.72H442.693V141.6Z"
        fill={resolvedColor}
      />
      <path
        d="M514.684 141.6C514.684 157.067 506.471 165.813 496.338 165.813C486.204 165.813 477.884 157.067 477.884 141.6C477.884 126.133 486.098 117.28 496.338 117.28C506.684 117.28 514.684 126.133 514.684 141.6ZM534.098 141.6C534.098 117.067 518.204 101.6 500.284 101.6C491.004 101.6 480.551 107.147 477.351 114.507V103.2H459.218V206.667H477.884V169.867C481.618 176.8 491.751 181.6 500.284 181.6C518.204 181.6 534.098 166.133 534.098 141.6Z"
        fill={resolvedColor}
      />
      <path
        d="M601.136 141.6C601.136 157.067 593.349 165.813 583.216 165.813C573.082 165.813 565.296 157.067 565.296 141.6C565.296 126.133 573.082 117.28 583.216 117.28C593.562 117.28 601.136 126.133 601.136 141.6ZM620.549 141.6C620.549 117.067 604.549 101.6 583.216 101.6C561.882 101.6 545.882 117.067 545.882 141.6C545.882 166.133 561.882 181.6 583.216 181.6C604.549 181.6 620.549 166.133 620.549 141.6Z"
        fill={resolvedColor}
      />
      <path
        d="M687.053 103.2L666.573 160.48L646.839 103.2H626.359L656.546 180H676.919L707.639 103.2H687.053Z"
        fill={resolvedColor}
      />
      <path
        d="M733.667 133.387C735.587 122.293 741.774 116 750.094 116C758.094 116 764.067 121.76 765.881 133.387H733.667ZM784.974 141.6C784.974 116.747 770.787 101.6 749.774 101.6C728.761 101.6 713.507 117.6 713.507 141.6C713.507 165.813 730.467 181.6 753.294 181.6C763.854 181.6 772.814 178.4 780.601 171.573L769.721 160.693C766.094 164.427 760.334 166.667 754.361 166.667C743.267 166.667 735.054 159.2 733.347 146.72H784.974V141.6Z"
        fill={resolvedColor}
      />
      <path
        d="M846.086 101.6C831.472 101.6 823.579 106.507 819.632 114.933V103.2H801.499V180H820.166V141.813C820.166 128.907 825.179 121.013 834.139 119.093C837.552 118.24 842.139 118.24 846.086 118.667V101.6Z"
        fill={resolvedColor}
      />
      <path
        d="M909.1 103.2L888.62 160.48L868.886 103.2H848.406L878.593 180H898.966L929.686 103.2H909.1Z"
        fill={resolvedColor}
      />
      <path
        d="M960.088 103.2H941.421V180H960.088V103.2ZM960.301 73.3334H941.208V91.4667H960.301V73.3334Z"
        fill={resolvedColor}
      />
      <path
        d="M997.902 133.387C999.822 122.293 1006.01 116 1014.33 116C1022.33 116 1028.3 121.76 1030.11 133.387H997.902ZM1049.21 141.6C1049.21 116.747 1035.02 101.6 1014.01 101.6C992.995 101.6 977.742 117.6 977.742 141.6C977.742 165.813 994.702 181.6 1017.53 181.6C1028.09 181.6 1037.05 178.4 1044.84 171.573L1033.96 160.693C1030.33 164.427 1024.57 166.667 1018.59 166.667C1007.5 166.667 999.288 159.2 997.582 146.72H1049.21V141.6Z"
        fill={resolvedColor}
      />
      <path
        d="M1150.32 103.2L1136.67 161.333L1121.84 103.2H1103.17L1088.35 161.333L1074.69 103.2H1055.07L1077.25 180H1098.37L1112.45 124.32L1126.64 180H1147.76L1169.95 103.2H1150.32Z"
        fill={resolvedColor}
      />
    </Svg>
  )
}
